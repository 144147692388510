import React from "react";

class Form extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            is_send_msg: false,
            send_msg: null
        }
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]: value});
    };

    submitHandler = (e) => {
        e.preventDefault();

        const api_key = 'a987cc1542f5f1e87058cac348b520b6-bbbc8336-99003e9a';
        const domain = 'letter.klipshop.com';

        const messageData = {
            from: 'nuumcosmetics.com <reviews@letter.klipshop.com>',
            to: 'aivaras@klipshop.lt, info@nuumcosmetics.com',
            subject: 'Užklausa iš vartotojo: '+this.state.name+' - '+this.state.email,
            text: 'Message: '+this.state.message,
        };

        const mailgun = require('mailgun-js')({apiKey:api_key,domain:domain, host: 'api.eu.mailgun.net'});

        var self = this;
        mailgun.messages().send(messageData,function(error,res){

          if(error){
              alert('Message not send')
              return
          }

         document.getElementById("form_class").reset();
         self.setState({is_send_msg: true, send_msg: res.message})

        })
    };

    render() {

        if (this.state.is_send_msg === true) {
            return (
                <div className={"message-send-succesful"}>
                    {this.state.send_msg}
                </div>
            )
        }

        return (
            <form onSubmit={this.submitHandler.bind(this)} id="form_class" className="form_class">

                <div className="row">
                    <div className="col-lg-6">
                        <input
                            type="text"
                            id="name"
                            name="name"
                            required={true}
                            className="form-control"
                            placeholder="Your Name*"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="col-lg-6">
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            required={true}
                            placeholder="Your Email*"
                            onChange={this.handleChange}
                        />
                    </div>
                </div>

                <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="6"
                    required={true}
                    placeholder="Your Message ...*"
                    onChange={this.handleChange}
                >
                </textarea>

                <button type="submit" className="btn send_btn theme_btn">
                    Send Message
                </button>
            </form>
        );
    }
}

export default Form;
