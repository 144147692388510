import React, { Component } from "react";

class NavbarThree extends Component {
  constructor() {
    super();
    this.state = {
      isExpanded: false,
    };
  }

  handleToggle() {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  }
  render() {
    var { mainlogo } = this.props;
    return (
      <React.Fragment>
        <header className="header_area_one p_absoulte">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-sm-9 col-7">
                <div className="menu_left">
                  <a href="/" className="logo" title={"Nuum Cosmetics"}>
                    <img src={require("../../image/vitamins/nuum logo baltas 60.svg")} alt="Nuum Cosmetics" />
                  </a>
                </div>
              </div>
              <div className="col-sm-3 col-5">
                <div className="menu_right">
                  <div className="burger_menu" onClick={() => this.handleToggle()}>
                    <span className="text" data-text="menu"></span>
                    <div
                      id="nav-icon2"
                      className={this.state.isExpanded ? "open" : ""}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div
          className={
            this.state.isExpanded ? "offcanvas_menu open" : "offcanvas_menu"
          }
        >
          <div className="closer_icon" onClick={() => this.handleToggle()}>
            <i className="icon_close"></i>
          </div>
          <div className="menu_body">
            <div className="menu_title">
              <h2>Soft Gums</h2>
              <p>
                Using premium quality ingredients, we have developed nutritional supplements that improve and support good health. Our soft gummies are not only beautiful but also very tasty.
              </p>
            </div>
            <div className="off_menu">
              <ul id="menu" className="nav">
                <li  className="">
                  <a onClick={() => this.handleToggle()} href="/#Mango">Daily Immunity</a>
                </li>
                <li  className="">
                  <a onClick={() => this.handleToggle()} href="/#Raspberry">Hair Skin Nails</a>
                </li>
                <li  className="">
                  <a onClick={() => this.handleToggle()} href="/#Forest">Kids Immunity</a>
                </li>
                <li  className="">
                  <a onClick={() => this.handleToggle()} href="/#Contact">Contact</a>
                </li>
              </ul>
            </div>
            <div className="copy_right_menu">
              <p>
                © <a title={"Nuum Cosmetics"} href="/">Nuumcoismetics.com</a> {(new Date().getFullYear())}. All Rights Reseverd
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default NavbarThree;
