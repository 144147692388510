import React, { Component } from "react";
import SplitText from "react-pose-text";

const charPoses = {};

class SectionTwo extends Component {
  render() {
    return (
      <div className="section fp-section fp-tabble">
        <div className="slider_container">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="slider_content slider_content_two">
                  <h6>
                    <span className="br"></span>
                    <SplitText charPoses={charPoses}>Raspberry Flavor</SplitText>
                  </h6>
                  <h2>
                    <SplitText charPoses={charPoses}>
                      Hair Skin Nails Soft Gums
                    </SplitText>
                  </h2>
                  <p>
                    Zinc, Biotin and Selenium help to maintain healthy hair. Selenium and Zinc help to maintain healthy nails. Vitamin A, Vitamin C, Biotin, Iodine, Niacin and Zinc contribute to the maintain healthy skin.
                  </p>
                  <a href="https://klipshop.co.uk/nuum-cosmetics-hair-skin-nails-soft-gums-food-supplement" className="see_btn" data-text="See Product">
                    See Product
                    <span className="arrow">
                      <span className="line"></span>
                    </span>
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="slider_image_inner">
                  <span></span>
                  <img
                    src={require("../../image/vitamins/antra foto.jpg")}
                    alt="Hair Skin Nails Soft Gums"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionTwo;
