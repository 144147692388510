import React, { Component } from "react";
import SplitText from "react-pose-text";
const charPoses = {};

class SectionOne extends Component {
  render() {
    return (
      <div className="section fp-section fp-tabble section_one">
        <div className="slider_container">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="slider_content">
                  <h6>
                    <span className="br"></span>
                    <SplitText charPoses={charPoses}>Mango Flavor</SplitText>
                  </h6>
                  <h2>
                    <SplitText charPoses={charPoses}>
                      Daily Immunity Soft Gums
                    </SplitText>
                  </h2>
                  <p>
                    Vitamins A, B6, B12, C, D3, folic acid and zinc contribute to the normal functioning of the immune system. Vitamin C, E and zinc help to protect cells against oxidative stress.
                  </p>
                  <a href="https://klipshop.co.uk/nuum-cosmetics-daily-immunity-soft-gums-food-supplement" className="see_btn" data-text="See Product">
                    See Product
                    <span className="arrow">
                      <span className="line"></span>
                    </span>
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="slider_image_inner">
                  <span></span>
                  <img
                    src={require("../../image/vitamins/pirma foto.jpg")}
                    alt="Daily Immunity Soft Gums"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionOne;
