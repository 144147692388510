import React, { Component } from "react";
import Reveal from "react-reveal/Reveal/";
import Form from "../Form";

class SectionFour extends Component {
  render() {
    let jhonData = this.props.jhonData;
    return (
      <div className="section fp-section fp-tabble section_one contact-area">
        <div className="slider_container">
          <div className="container">
            <div className="row row-reverse">
              {jhonData.contact &&
                jhonData.contact.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <div className="col-md-5">
                        <Reveal effect="fadeInLeft" duration={500}>
                          <div className="contact_info">
                            <h4>{item.title}</h4>
                            <ul className="nav">

                              <li className="item">
                                <div className="media">
                                  <a target={'_blank'} href="https://goo.gl/maps/CVc2fS38EX1va7689">
                                    <i className={'icon_pin_alt'}></i>
                                  </a>
                                  <div className="media-body">
                                    <a target={'_blank'} href="https://goo.gl/maps/CVc2fS38EX1va7689">Vilniaus g. 215, Šiauliai 76348, Lithuania</a>
                                  </div>
                                </div>
                              </li>
                              {/*<li className="item">*/}
                              {/*  <div className="media">*/}
                              {/*    <a href="tel:+37069820093">*/}
                              {/*      <i className={'icon_phone'}></i>*/}
                              {/*    </a>*/}
                              {/*    <div className="media-body">*/}
                              {/*      <a href="tel:+37069820093">+370 698 20093</a>*/}
                              {/*    </div>*/}
                              {/*  </div>*/}
                              {/*</li>*/}
                              <li className="item">
                                <div className="media">
                                  <a href="mailto:info@nuumcosmetics.com">
                                    <i className={'icon_mail'}></i>
                                  </a>
                                  <div className="media-body">
                                    <a href="mailto:info@nuumcosmetics.com">info@nuumcosmetics.com</a>
                                  </div>
                                </div>
                              </li>

                            </ul>
                          </div>
                        </Reveal>
                      </div>
                    </React.Fragment>
                  );
                })}
              <div className="col-md-7">
                <Reveal effect="fadeInRight" duration={800}>
                  <div className="input_form">
                    <h4>{jhonData.contacttitle}</h4>
                    <Form />
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionFour;
