import React, { Component } from "react";
import SplitText from "react-pose-text";

const charPoses = {};
class SectionThree extends Component {
  render() {
    return (
      <div className="section fp-section fp-tabble section_one">
        <div className="slider_container">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="slider_content slider_content_three">
                  <h6>
                    <span className="br"></span>
                    <SplitText charPoses={charPoses}>Forest Fruits Flavor</SplitText>
                  </h6>
                  <h2>
                    <SplitText charPoses={charPoses}>
                      Kids Immunity Soft Gums
                    </SplitText>
                  </h2>
                  <p>
                    They are made with a complete blend of daily vitamins and minerals, to keep our little one's growing body strong and healthy.
                  </p>
                  <a href="https://klipshop.co.uk/nuum-cosmetics-kids-immunity-soft-gums-food-supplement" className="see_btn" data-text="See Product">
                    See Product
                    <span className="arrow">
                      <span className="line"></span>
                    </span>
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="slider_image_inner">
                  <span></span>
                  <img
                    src={require("../../image/vitamins/trecia foto.jpg")}
                    alt="Kids Immunity Soft Gums"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionThree;
